.App {
    text-align: center;
}

body {
    background-color: #f6f8fc;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dots-flashing {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
    0% {
        box-shadow: 20px 0 #70B9BE, -20px 0 #0002;
        background: #70B9BE
    }
    33% {
        box-shadow: 20px 0 #70B9BE, -20px 0 #0002;
        background: #0002
    }
    66% {
        box-shadow: 20px 0 #0002, -20px 0 #70B9BE;
        background: #0002
    }
    100% {
        box-shadow: 20px 0 #0002, -20px 0 #70B9BE;
        background: #70B9BE
    }
}

.gray-2 {
    color: #97A2B0;
}

.secondary-link {
    color: #3DA0A7;
    text-decoration: none;
    font-weight: bold;
}