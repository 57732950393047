.brandLogo {
    font-family: 'Roboto', serif;
    font-size: 16px;
    color: #042628;
    text-decoration: none;
    text-align: center;
}

.brandLogo-part2 {
    color: #70B9BE;
}