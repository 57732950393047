.NavBar {
    a {
        text-decoration: none;
        cursor: pointer;
    }

    .link {
        cursor: pointer;
        color: #fff;
    }

    .active {
        color: #fff;
        font-weight: bold;
    }

    a:hover {
        text-decoration: underline;
    }
}

.MuiBottomNavigationAction-root .Mui-selected {
    color: #70B9BE;
    font-weight: bold;
}

.MuiBottomNavigationAction-root.Mui-selected > .MuiSvgIcon-root {
    color: #70B9BE;
}